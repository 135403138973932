<!--
 * @Author: xiaziwen
 * @Date: 2021-01-10 12:23:52
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-10 13:33:09
 * @FilePath: \crm-education\src\views\student\my_textbook_send\index.vue
-->
<template>
  <div class="live-page">
    <a-layout-header style="margin-bottom: 15px">
      <div class="header-bar">
        <div class="main-width">
          <a-row>
            <a-col :xs="{ span: 12 }">
              <div class="header-left d-flex align-center">
                <span style="font-size: 28px">直播主题列表</span>
              </div>
            </a-col>
            <a-col :xs="{ span: 12 }">
              <div class="header-right d-flex align-center justify-end">
                <a-dropdown>
                  <div class="header-user d-flex align-center link">
                     <a-avatar :size="24" icon="user" :src="user.studentImg"></a-avatar>
                     <span class="pl-5">{{ user.studentName }}</span>
                  </div>
                  <a-menu slot="overlay">
                    <a-menu-item>
                       <a href="javascript:;" @click="logout">退出</a>
                    </a-menu-item>
                    <!-- <a-menu-item>
                <a href="javascript:;">选项一</a>
              </a-menu-item> -->
                  </a-menu>
                </a-dropdown>
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
    </a-layout-header>

    <a-row class="live-list">
      <a-card>
        <a-form class="cus-form" layout="inline" >
          <a-row type="flex" align="middle">
            <a-col :md="{ span: 6 }">
              <a-form-item label="直播名称">
                <a-input v-model="liveParam.specialName" placeholder="请输入直播名称"> </a-input>
              </a-form-item>
            </a-col>
            <a-col :md="{ span: 6 }">
              <a-form-item label="主讲人">
                <a-input v-model="liveParam.anchorTeacherName" placeholder="请输入主讲人"> </a-input>
              </a-form-item>
            </a-col>
            <a-col :md="{ span: 5 }">
              <a-form-item label="直播时间">
                <a-range-picker @change="onChange" :show-time="{ format: 'HH:mm:ss' }" format="YYYY-MM-DD HH:mm:ss" :placeholder="['选择开始时间', '选择结束时间']"/>
              </a-form-item>
            </a-col>
            <a-col style="margin: auto;margin-right: 30px;">
              <a-form-item>
                <a-button type="primary" html-type="submit" class="cus-button" @click="onQuery">
                  查询
                </a-button>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </a-card>

      <a-card class="no-border">
        <template slot="title">
          <div class="section-header pt-10 dec d-flex align-center justify-between flex-wrap">
            <h2 class="base-title fs-18">今日直播</h2>
            <p class="fs-14 color-gray link"></p>
          </div>
        </template>
        <a-col :span="6" class="live-list-col" v-for="o in todayData" :key="o.specialId" @click="handleLiveClick(o)">
          <a-card hoverable>
            <div
                    class="detail d-flex col align-center justify-center"
                    :style="{ backgroundImage: 'url(' + (o.liveImg ? o.liveImg : '') + ')' }"
            >
              <section v-show="isShow(o)">
                <p class="title fs-18 mb-15 fw-bold">{{ o.liveName }}</p>
                <p class="sub-author fs-14 fw-bold">主讲教师：{{ o.anchorTeacherName }}</p>
              </section>
            </div>
            <div class="meta fs-12" v-show="statusTxt(o)">{{ o.text }}</div>
            <a-card-meta class="text-left">
              <template slot="title">
                <p class="live-title fs-16 fw-bold">{{ o.liveName }}</p>
              </template>
              <template slot="description">
                <slot :options="o">
                  <p class="author fs-14">主讲：{{ o.anchorTeacherName }}</p>
                  <p class="date fs-14">时间：{{ o.liveStartTime }} ~ {{ o.liveEndTime }}</p>
                </slot>
              </template>
            </a-card-meta>
          </a-card>
        </a-col>
      </a-card>

      <a-card class="no-border">
        <template slot="title">
          <div class="section-header pt-10 dec d-flex align-center justify-between flex-wrap">
            <h2 class="base-title fs-18">所有直播</h2>
            <p class="fs-14 color-gray link"></p>
            <a-select v-model="liveParam.termId" style="width: 120px" @change="onSelectChange" placeholder="请选择学期">
              <a-select-option v-for="term in termList" :value="term.termId" :key="term.termId">
                {{ term.termName }}
              </a-select-option>
            </a-select>
          </div>
        </template>
        <a-col :span="6" class="live-list-col" v-for="o in data" :key="o.specialId" @click="handleClick(o)">
          <a-card hoverable>
            <div class="detail d-flex col align-center justify-center"
                 :style="{ backgroundImage: 'url(' + (o.specialImg ? o.specialImg : '') + ')' }"
            >
              <p class="sub-author fs-14 fw-bold" v-show="isShow(o)">主讲教师：{{ o.anchorTeacherName }}</p>
            </div>
            <div class="hover-mask d-flex align-center justify-center">
              <button type="button" class="ant-btn ant-btn-primary" @click="handleClick(o)"><span>查看详情</span></button>
            </div>
            <a-card-meta class="text-left">
              <template slot="title">
                <p class="live-title fs-16 fw-bold">{{ o.specialName }}</p>
              </template>
              <template slot="description">
                <slot :options="o">
                  <p class="author fs-14">主讲：{{ o.anchorTeacherName }}</p>
                  <p class="date fs-14">时间：{{ o.nearTime }}</p>
                </slot>
              </template>
            </a-card-meta>
          </a-card>
        </a-col>
      </a-card>
    </a-row>

  </div>
</template>

<script>
import api from '@/api'
import { mapState } from 'vuex'
import helper from '@/utils/helper'
import ACard from "ant-design-vue/es/card/Card";
export default {
  name: 'live_page',
  components: {ACard},
  data() {
    return {
      channelNo: '',
      data: [],
      todayData: [],
      termList: [],
      termActiveKey: '',
      liveParam: {
          anchorTeacherName: '',
          specialName: '',
          termId: '',
          startTime: '',
          endTime: '',
          organizationId: localStorage.getItem('organizationId'),
      },
      option: {},
      redirect:'/teacherLive/list'
    }
  },
  computed: {
    ...mapState(['userRole', 'noReadMsg', 'user']),
  },
  methods: {
    logout() {
         helper.clearUserInfo()
         this.$router.replace({ name: 'login_teacherLive' })
    },
    async getData() {
      let res = await api.teacher.getLiveSpecialByOrganization(this.liveParam)
      this.data = res.data
    },
    async getTodayData() {
      let res = await api.teacher.getTodayLiveInfoByOrganization(this.liveParam)
      this.todayData = res.data
    },
    async handleLiveClick(option) {
          const { liveId = '', liveState = '', channelNo = '', openType = '', recordVideoUrl = '' } = option
          if (liveState === '2') {
              this.$message.warning('直播未开始')
              return
          }
          if (liveState === '4') {
              this.$message.warning('未上课')
              return
          }
          if (liveState === '1' || liveState === '3') {
              // 校验是否是小鱼易连直播
              let res = await api.student.checkXyLive({ liveId })
              if (res.data && !recordVideoUrl) {
                  this.$message.warning('课程未导入直播地址')
                  return;
              }

              const { data = {} } = await api.student.getLiveUrl({ liveState, channelNo, openType, recordVideoUrl,liveId })
              if (data) {
                  window.open(data, '_blank')
              } else {
                  this.$message.warning('暂无直播地址')
              }
          }
    },
    handleClick(option) {
        let channelNo = option.channelNo;
        if (!channelNo) {
            channelNo = option.specialId
        }
        let path = this.redirect + "/" + channelNo;
        let routeData = this.$router.resolve({
            path: path,
            query:{
                channelNo: option.channelNo,
                livePlatform: option.livePlatform,
                specialId: option.specialId
            }
        })
        window.open(routeData.href, '_blank');
    },
    statusTxt(option) {
        const { liveState = '', countdown = '' } = option
        let text = ''
        if (liveState === '1' && Number(countdown) <= 0) {
            text = `直播进行中`
        } else if ((liveState === '2' || liveState === '1') && Number(countdown) > 0) {
            text = `即将开始（${countdown}小时之后）`
        } else if (liveState === '3') {
            text = '看回放'
        } else if (liveState === '4') {
            text = '未上课'
        }
        option.text = text
        return text
    },
    isShow(option){
       if(option.specialImg){
         return false
       }
       return true
    },
    onQuery() {
        this.getData()
        this.getTodayData()
    },
    async onSelectChange() {
        this.matchCurrentTerm(this.termList)
    },
    matchCurrentTerm(termList = []) {
        const current = termList.filter(ele => ele.termId === this.liveParam.termId)
        this.getData()
    },
    async initTermDicts() {
        let termRes = await api.teacher.getTermByOrganizationId({organizationId: localStorage.getItem('organizationId')})
        if (termRes.code === 200 && termRes.data.length > 0) {
            this.termList = termRes.data
            this.termActiveKey = termRes.data[0].termId
            let temp = termRes.data.filter(item => item.currentTeaching === 'Y')
            if(temp!=null && temp.length>0){
                this.termActiveKey = temp[0].termId
            }
            this.liveParam.termId = this.termActiveKey
            await this.getData()
        }
    },
    async init() {
        this.initTermDicts()
        await this.getTodayData()
    },
    onChange(date, dateString) {
        this.liveParam.startTime = dateString[0];
        this.liveParam.endTime = dateString[1];
    },
  },
  beforeCreate() {},
  created() {},
  mounted() {
    this.init()
  },
}
</script>
<style lang="scss">
.live-page {
  .live-list {
    max-width: 1200px;
    margin: 0 auto;
    .live-list-col {
      padding: 15px 15px;
      position: relative;
      .ant-card:hover .hover-mask {
        opacity: 1;
      }
      .hover-mask {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.3);
        opacity: 0;
        -webkit-transition: all 0.5s;
        transition: all 0.5s;
      }
      .align-center {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
      }
      .justify-center {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
      }
      .d-flex {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
      }
      .detail {
        height: 140px;
        background-color: #e74e3e;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        color: #fff;
      }
      .meta {
        position: absolute;
        right: 40%;
        top: 25%;
        background-color: #385dfb;
        color: #fff;
        padding: 2px 4px;
      }
      .hover-mask {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.3);
        opacity: 0;
        transition: all 0.5s;
      }
      .ant-card-body {
        padding: 0 0;
      }
      .ant-card-meta-detail {
        padding: 10px 10px;
      }
    }
    img {
      height: 140px;
    }
  }
  .header-bar {
    height: 64px;
    .header-left {
      cursor: pointer;
    }
    .header-log {
      display: inline-block;
      width: 40px;
      height: 28px;
      background: url('../../../assets/img/logo.png') no-repeat center center;
    }

    .header-user {
      width: 100px;
    }
  }
  .panel-card {
    border-radius: 5px;
    overflow: hidden;
    .ant-card-body {
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 20px;
    }
    .live-title {
      padding-top: 20px;
    }
    &:hover {
      .live-title {
        color: #385dfb;
      }
      .hover-mask {
        opacity: 1;
      }
    }
    .author,
    .date {
      padding-bottom: 5px;
    }
  }
}
</style>
